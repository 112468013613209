import React from 'react';
import { useTranslation } from 'react-i18next';
import PartLink from './PartLink';

const SourcePart = ({ part }) => {

  const { t } = useTranslation();

  return part ? (

    <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('parts.part_id')}</span>
          </div>
          <ul className='list-group list-group-flush'>
            <PartLink part={part}/>
          </ul>
        </div>
      </div>
    </div>) : <></>

}

export default SourcePart
