import React from 'react';
import { useTranslation } from 'react-i18next';
import PartLink from './PartLink';

const TargetParts = ({ parts }) => {

  const { t } = useTranslation();


  return parts && parts.length > 0 && (

    <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('parts.target_parts')}</span>
          </div>
          <ul className='list-group list-group-flush'>
            {parts.map(part => <PartLink part={part} key={part.uuid}/>)}
          </ul>
        </div>
      </div>
    </div>
  )

}

export default TargetParts
