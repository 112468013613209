import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PartLink = ({ part }) => {

  const { t } = useTranslation();

  return (
    <li className="list-group-item px-1">
      <Link to={`/parts/${part.uuid}`}>
        {part.code}
      </Link><br/>
      {!!!part?.description ? t('no-results') : part?.description}
    </li>
  )

}

export default PartLink
