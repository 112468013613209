import React, { useContext } from 'react';
import Drawer from '../UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import WorkPicker from '../UI/Forms/Pickers/WorkPicker';
import ColorPresetPicker from '../UI/Forms/Pickers/ColorPresetPicker';
import { ErrorMessage, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { formConfig } from './form.config';
import { toast } from 'react-toastify';
import colorConfigsService from '../../services/colors/colorConfigsService';
import { AppContext } from '../AppContext';
import { FormActions } from '../UI/Forms/FormActions';
import { List } from '../UI/ItemList/List';
import { ListItem } from '../UI/ItemList/ListItem';
import { InfoTooltip } from '../UI/Tooltips/InfoTooltip';
import { TagList } from '../UI/Tags/TagList';
import ColorPicker from '../UI/Forms/Pickers/ColorPicker';
import { FormInput } from '../UI/Forms/FormInput';


const ColorConfigsDrawer = ({ isOpen, serviceParams, serviceCall, colorConfigs = [], colorParts, setIsOpen, onUpdate, canEdit = true }) => {

  const { t } = useTranslation()
  const { setInitialValues, validation, mapValuesForRequest, mapValuesFromPreset } = formConfig

  const { setLoadingOverlayState } = useContext(AppContext)

  const formik = useFormik({
    initialValues: setInitialValues(colorParts, colorConfigs),
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoadingOverlayState(true)
      serviceCall(serviceParams, mapValuesForRequest(values)).then((res) => onUpdate(res))
      .catch((err) => toast.error(err?.response?.data?.message))
      .finally(() => {
        setSubmitting(false)
        setLoadingOverlayState(false)
      })
    }
  })

  const { values, setFieldValue, setValues } = formik;

  const onWorkSelected = work => {
    work &&
    colorConfigsService.fetchAll(work.uuid)
    .then(res => {
      if (res.length < 1) {
        toast.error(t('works.color-configs-empty'))
      } else {
        formik.setValues(setInitialValues(colorParts, res))
      }
    })
    .catch(err => toast.error(err?.response?.data?.message))
  }
  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('color_configs.sing')}
    >
      <FormikProvider value={formik}>
        <Form>
          <div className='d-flex flex-column gap-4'>
            <List>
              {canEdit &&
                <div>
                  <h5>Carica configurazione da una commessa esistente</h5>
                  Scrivi e seleziona una commessa dal menù a tendina per importare automaticamente la configurazione colori di un'altra commessa.
                  <WorkPicker defaultParams={[]} handleChange={onWorkSelected}/>
                </div>}

              {canEdit &&
                <div>
                  <h5>Carica configurazione da un preset</h5>
                  Scrivi e seleziona un preset per importare automaticamente la configurazione colori.
                  <ColorPresetPicker defaultParams={[]} handleChange={v => setValues(setInitialValues(colorParts, v?.color_configs))}/>
                </div>}

              {canEdit &&
                <div>
                  <h5>Configura manualmente</h5>
                  Spunta il nome della parte da configurare per abilitarla, seleziona uno o più colori dal menù a tendina.
                </div>}

              <FieldArray name='color_configs'>
                {() => (
                  <>
                    {values.color_configs.map((colorConfig, index) =>
                      <ListItem
                        label={colorConfig?.color_part.description}
                        extra={<InfoTooltip message={colorConfig?.note}/>}
                        key={index}>

                        {canEdit
                          ? <div className='d-flex flex-column gap-2'>
                            <ColorPicker
                              colorPart={colorConfig.color_part.id}
                              handleChange={v => setFieldValue(`color_configs.${index}.colors`, v)}
                              defaultOptions={values.color_configs[index].colors}
                              value={values.color_configs[index].colors}
                              isMulti
                            />
                            <ErrorMessage
                              name={`color_configs.${index}.colors`}
                              component="div"
                              className="d-block invalid-feedback"
                            />
                            <FormInput
                              name={`color_configs.${index}.note`}
                              as='textarea'
                              placeholder={t('note')}
                              rows={2}
                            />
                            <ErrorMessage
                              name={`color_configs.${index}.note`}
                              component="div"
                              className="d-block invalid-feedback"
                            />

                          </div>
                          : <TagList tags={colorConfig.colors}/>}
                      </ListItem>
                    )}
                  </>
                )}
              </FieldArray>

            </List>

            {canEdit &&
            <FormActions
              withReset={true}
              onCancel={() => setSubmitting(false)}
            />}

          </div>
        </Form>
      </FormikProvider>
    </Drawer>

  )

}

export default ColorConfigsDrawer
