import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, InputGroup, InputGroupText, Row, } from 'reactstrap';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import partCollectionService from '../../../services/parts/partCollectionsService';
import formConfig from '../components/form.config';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { FormSelect } from '../../../components/UI/Forms/FormSelect';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import codesService from '../../../services/parts/codesService';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';

function MeasureCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: partCollections,
    loading: partCollectionLoading,
  } = useRequest(partCollectionService.fetchAll);
  const [currentPartCollection, setCurrentPartCollection] = useState(undefined);

  return (
    <Formik
      initialValues={formConfig.measureInitialValues}
      validationSchema={formConfig.storeMeasureValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        codesService.store(CodeTypeEnum.values.MEASURE, values)
        .then((res) => {
          toast.success(t('created'));
          navigate(`../${res.uuid}`);
        })
        .catch((err) => toast.error(err?.response?.data?.message))
        .finally(() => setSubmitting(false));
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <h5 className="text-uppercase">{t('enums.code_types.MEASURE')}</h5>
          <Row>
            <Col xl={4} lg={6}>
              <Card>
                <CardBody>
                  <FormGroup label={t('codes.code')} name="code" required>
                    <InputGroup>
                      <InputGroupText>
                        <span className={clsx('fw-bold', {
                          'text-danger': !currentPartCollection,
                        })}
                        >
                          {currentPartCollection?.letter
                            || <i className="fa fa-exclamation-circle"/>}
                        </span>
                      </InputGroupText>
                      <FormInput name="code"/>
                    </InputGroup>
                  </FormGroup>
                  <FormDisplay label={t('description')} inline={false}>
                    {`${values.width}X${values.depth}XH${values.height}`}
                  </FormDisplay>
                  <div className="d-flex align-items-center justify-content-between">
                    <CancelButton onClick={() => navigate('../')}/>
                    <FormikSaveButton/>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} md={6}>
              <Card>
                <CardBody>
                  <FormGroup
                    label={t('part_collections.sing')}
                    name="part_collection_id"
                    required
                    inline={false}
                  >
                    <FormSelect
                      name="part_collection_id"
                      multiple={false}
                      loading={partCollectionLoading}
                      onChange={(value) => setCurrentPartCollection(
                        value ? partCollections?.find((pc) => pc.id === value,) : undefined)
                      }
                      options={(partCollections || []).map((pc) => ({ ...pc, value: pc.id }))}
                    />
                  </FormGroup>

                  <Row>
                    <Col lg={4}>
                      <FormGroup label={t('width')} name="width" required inline={false}>
                        <FormInput name="width" type="number" step={1}/>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup label={t('depth')} name="depth" required inline={false}>
                        <FormInput name="depth" type="number" step={1}/>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup label={t('height')} name="height" required inline={false}>
                        <FormInput name="height" type="number" step={1}/>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      )}

    </Formik>
  );
}

export default MeasureCreate;
