export const offerActionTypes = {

  INIT: 'INIT',
  CHANGE_TAB: 'CHANGE_TAB',

  //LOADER
  SET_LOADING: 'SET_LOADING',
  UNSET_LOADING: 'UNSET_LOADING',

  // REVISION
  UPDATE_COLOR_CONFIG: "UPDATE_COLOR_CONFIG",
  CHANGE_OFFER_REVISION: 'ADD_OFFER_REVISION',
  CHANGE_OFFER_REVISION_STATE: 'CHANGE_OFFER_REVISION_STATE',
  UPDATE_OFFER_REVISION_STATES: 'UPDATE_OFFER_REVISION_STATES',
  UPDATE_REVISION: 'UPDATE_REVISION',
  UPDATE_REVISION_CATEGORY_MARGIN: 'UPDATE_REVISION_CATEGORY_MARGIN',
  MASSIVE_PRICE_UPDATE: 'MASSIVE_PRICE_UPDATE',

  // SUB_OFFERS
  ADD_SUB_OFFER: 'ADD_SUB_OFFER',
  UPDATE_SUB_OFFER: 'UPDATE_SUB_OFFER',
  REMOVE_SUB_OFFER: 'REMOVE_SUB_OFFER',
  TOGGLE_SUB_OFFER: 'TOGGLE_SUB_OFFER',

  // SUB OFFER TOOLS
  UPDATE_SUB_OFFER_TOOLS: 'UPDATE_SUB_OFFER_TOOLS',
  UPDATE_SUB_OFFER_TOOLS_FROM_FILE: 'UPDATE_SUB_OFFER_TOOLS_FROM_FILE',

  // ITEMS
  ADD_SUB_OFFER_ITEM: 'ADD_SUB_OFFER_ITEM',
  UPDATE_SUB_OFFER_ITEM: 'UPDATE_SUB_OFFER_ITEM',
  SWAP_SUB_OFFER_ITEM_POSITION: 'SWAP_SUB_OFFER_ITEM_POSITION',
  REMOVE_SUB_OFFER_ITEM: 'REMOVE_SUB_OFFER_ITEM',

  ADD_SUB_OFFER_TOOL_ITEM: 'ADD_SUB_OFFER_TOOL_ITEM',
  UPDATE_SUB_OFFER_TOOL_ITEM: 'UPDATE_SUB_OFFER_TOOL_ITEM',
  REMOVE_SUB_OFFER_TOOL_ITEM: 'REMOVE_SUB_OFFER_TOOL_ITEM',
}
