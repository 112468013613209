import React from 'react';
import { useTranslation } from 'react-i18next';
import EuroDisplay from '../../../../../components/UI/Displays/EuroDisplay';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardSmMargin } from '../../../../../components/UI/Card/CardSmMargin';


const SubOfferToolsItem = ({ offerRevision }) => {

  const { t } = useTranslation()


  return (
    <CardSmMargin>
      <CardBody>
        <div className='d-flex justify-content-between align-items-center gap-4'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-nowrap align-items-center justify-content-start fs-5'>
              <div className='fw-bold '>{t('sub_offers.tools')}</div>
            </div>
            <div>{t('sub_offers.tools_description')}</div>
          </div>
          <div className='d-flex justify-content-end gap-4 flex-wrap text-end'>
            <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "50px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.qty')}</span>
                <span className='fw-bold'>{offerRevision.sub_offer_tools.qty}</span>
              </div>
            </div>
            <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "250px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_price_total')}</span>
                <span className='fw-bold text-info'><EuroDisplay value={offerRevision.sub_offer_tools.price}/></span>
              </div>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_total_price_discount')}</span>
                <span className='fw-bold text-info'><EuroDisplay value={offerRevision.sub_offer_tools.total_net_price}/></span>
              </div>
            </div>
            <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "300px" }}/>
            <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "100px" }}/>
          </div>
        </div>
      </CardBody>
    </CardSmMargin>
  )

}

export default SubOfferToolsItem
