import React, { useContext } from 'react';
import { OfferContext } from '../offerContext/offerContext';
import { offerActionTypes } from '../offerContext/offerReducer/offerActionTypes';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next';
import offerRevisionsService from '../../../../services/offers/offerRevisionsService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../components/AppContext';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { List } from '../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../components/UI/ItemList/ListItem';
import { InfoTooltip } from '../../../../components/UI/Tooltips/InfoTooltip';
import { FormPercentage } from '../../../../components/UI/Forms/FormPercentage';
import { FormEuro } from '../../../../components/UI/Forms/FormEuro';
import PercentageDisplay from '../../../../components/UI/Displays/PercentageDisplay';
import { Tag } from '../../../../components/UI/Tags/Tag';
import { FormActions } from '../../../../components/UI/Forms/FormActions';
import FormRichText from '../../../../components/UI/Forms/FormRichText';
import MarketDisplay from '../../../../components/UI/Displays/MarketDisplay';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';

const OfferDetails = ({ offer }) => {

  const { dispatchAction, revision_editable } = useContext(OfferContext)
  const { t } = useTranslation()

  const initialValues = {
    discount: (offer.offer_revision.discount * 100).toFixed(2),
    accepted_price: offer.offer_revision.accepted_price,
    note: offer.offer_revision.note ?? ""
  }

  const validationSchema = Yup.object().shape({
    discount: Yup.number().min(0).max(100).required(),
    accepted_price: Yup.number().min(0).required(),
    note: Yup.string().nullable().notRequired()
  })

  const { setLoadingOverlayState } = useContext(AppContext)

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoadingOverlayState(true)
      offerRevisionsService.update(offer.uuid, offer.offer_revision.uuid, values)
      .then(res => dispatchAction({
        type: offerActionTypes.UPDATE_REVISION,
        payload: res
      }))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => {
        setSubmitting(false)
        setLoadingOverlayState(false)
      })
    }
  })


  return offer?.offer_revision ? (
      <FormikProvider value={formik}>
        <Form>
          {offer?.work !== null &&
            <Card>
              <CardBody>
                <List>
                  <ListItem label={t('market')} inline={true}>
                    <MarketDisplay market={offer.company?.market}/>
                  </ListItem>
                  <ListItem label={t('companies.business_name')} inline={true}>
                    {offer?.company?.business_name}
                  </ListItem>
                  <ListItem label={t('companies.beta_pricing_discount')} inline={true}>
                    <Tag color='secondary'>
                      <PercentageDisplay value={offer.offer_revision.company_beta_pricing_discount * 100}/>
                    </Tag>
                  </ListItem>
                  <ListItem label={t('companies.internal_pricing_discount')} inline={true}>
                    <Tag color='secondary'>
                      <PercentageDisplay value={offer.offer_revision.company_pricing_discount * 100}/>
                    </Tag>
                  </ListItem>
                  <ListItem label={t('companies.internal_pricing_increment')} inline={true}>
                    <Tag color='secondary'>
                      <PercentageDisplay value={offer.offer_revision.company_pricing_increment * 100}/>
                    </Tag>
                  </ListItem>
                  <ListItem
                    label={t('offer_revisions.percentage_modifier')}
                    inline={true}
                  >
                    <Tag color='secondary'>
                      <PercentageDisplay value={offer.offer_revision.percentage_modifier * 100}/>
                    </Tag>
                  </ListItem>
                </List>
              </CardBody>
            </Card>
          }
          <Card>
            <CardBody>
              <List>
                <ListItem
                  inline={true}
                  label={t('offers.discount')}
                  extra={<InfoTooltip message='Sconto totale offerta. Agisce sul prezzo totale netto di ciascuna parte.'/>}>
                  {revision_editable
                    ? <FormPercentage
                      value={formik.values.discount}
                      step={0.01}
                      min={0}
                      max={100}
                      name='discount'
                    />
                    : <PercentageDisplay value={formik.values.discount}/>
                  }
                </ListItem>
              </List>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardLabel>{t('offers.total_department_setup')}</CardLabel>
              <List>
                <ListItem
                  inline={true}
                  label={t('offers.accepted_price')}
                  extra={<InfoTooltip message="Prezzo al Cliente finale. Calcola l'indice di sconto tra il prezzo della composizione e il prezzo accettato."/>}>
                  {revision_editable
                    ? <FormEuro
                      value={formik.values.accepted_price}
                      handleChange={value => formik.setFieldValue('accepted_price', value)}
                      step={0.01}
                      min={0}
                      name='accepted_price'
                    />
                    : <EuroDisplay value={formik.values.accepted_price}/>
                  }


                </ListItem>
                <ListItem
                  inline={true}
                  label={t('offers.margin_rate')}
                  extra={<InfoTooltip message="Marginalità calcolata su costo e totale prezzo netto."/>}>
                  <Tag color='danger'>
                    <PercentageDisplay value={offer.offer_revision.margin_rate * 100}/>
                  </Tag>
                </ListItem>
                <ListItem
                  inline={true}
                  label={t('offers.full_margin_rate')}
                  extra={<InfoTooltip message="Marginalità calcolata su costo + progettazione e totale prezzo netto."/>}>
                  <Tag color='danger'>
                    <PercentageDisplay value={offer.offer_revision.full_margin_rate * 100}/>
                  </Tag>
                </ListItem>
                <ListItem
                  inline={true}
                  label={t('offers.discount_accepted')}
                  extra={<InfoTooltip message="Sconto calcolato tra prezzo accettato e totale prezzo."/>}>
                  <Tag color='secondary'>
                    <PercentageDisplay value={offer.offer_revision.discount_accepted * 100}/>
                  </Tag>
                </ListItem>
                <ListItem
                  label={t('offer_revisions.note')}
                  extra={<InfoTooltip message={t("offer_revisions.note_description")}/>}
                >
                  {revision_editable
                    ? <FormRichText name="note" defaultValue={offer.offer_revision.note ?? ""}/>
                    : <div dangerouslySetInnerHTML={{ __html: offer.offer_revision.note ?? "" }}/>
                  }
                </ListItem>
              </List>
              {revision_editable && <FormActions withReset={false}/>}
            </CardBody>
          </Card>
        </Form>
      </FormikProvider>
    ) :
    <></>

}

export default OfferDetails
