import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import partsService from '../../../services/parts/partsService';
import PartStateChanger from '../components/PartStateChanger';
import { PartDetails } from './PartDetails/PartDetails';
import { PartCodeDetails } from './PartCodeDetails/PartCodeDetails';
import { PartCostsDetails } from './PartCostsDetails/PartCostsDetails';
import { PartPriceChart } from './PartPriceChart/PartPriceChart';
import PartMedia from './PartMedia/PartMedia';
import PartBoms from './PartBom/PartBoms';
import optionsService from '../../../services/optionsService';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PartWorks from './PartWorks/PartWorks';
import PartOffers from './PartOffers/PartOffers';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import PartTypeEnum from '../../../constants/enums/PartTypeEnum';
import SourcePart from './SourceTargetParts/SourcePart';
import TargetParts from './SourceTargetParts/TargetParts';
import SweetAlert from 'sweetalert2';


function Part() {
  const { id } = useParams();
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: part = undefined, run, mutate: setPart } = useRequest(partsService.fetch, { manual: true });
  const { data: categories = [] } = useRequest(optionsService.partCategories)
  useEffect(() => run(id), [id, run])


  const handleVaultSync = () => partsService.vaultSync(part?.uuid)
  .then(() => toast.success(t('updated')))
  .catch((err) => toast.error(err?.response?.data?.message))

  const handleCreateCustomFromJohnDoe = () => {
    if (part?.target_parts?.length > 0) {
      SweetAlert.fire({
        title: t('are_you_sure'),
        text: `${part.code} ha già generato ${part.target_parts.length} codici.`,
        icon: 'warning',
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          goToCustomCreation()
        }
      });
    }
  }

  const goToCustomCreation = () => {
    navigate('../create-custom', {
      state: {
        partId: part?.id,
        partCode: part?.code,
        partDescription: part?.description,
      }
    })
  }

  return part ? (
    <>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex align-items-center justify-content-between gap-4'>
          <div>
            <div className='fs-3 fw-bolder text-dark'>{part.code}</div>
            <div className='fs-5'>{part.description}</div>
          </div>
          <div className='d-flex justify-content-end align-items-center gap-2'>
            <PartStateChanger
              part={part}
              onStateChange={(state) => mutate((part) => ({ ...part, state }))}
            />
            <UncontrolledTooltip target='send_part_to_vault'>Invia anagrafica parte a Vault</UncontrolledTooltip>

            {part.part_type === PartTypeEnum.values.JOHN_DOE &&
              <CreateButton
                label={`${t('enums.part_types.CUSTOM')} FC`}
                onClick={handleCreateCustomFromJohnDoe}
              />}

            {part.part_type === PartTypeEnum.values.CUSTOM &&
              <UncontrolledButtonDropdown>
                <DropdownToggle color="primary" className='btn btn-light'>
                  <i className="fa fa-plus me-1"/>
                  {t('parts.sing')} STANDARD
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <a onClick={() => navigate('../create-with-measure', { state: part })}>
                      {t('enums.part_types.WITH_MEASURE')}
                    </a>
                  </DropdownItem>
                  <DropdownItem>
                    <a onClick={() => navigate('../create-without-measure', { state: part })}>
                      {t('enums.part_types.WITHOUT_MEASURE')}
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            }

            <button
              className='btn btn-danger btn-icon'
              type='button'
              id='send_part_to_vault'
              onClick={handleVaultSync}
            >
              <i className='fa fa-hdd'/>
            </button>
          </div>
        </div>


        <div className='row'>
          <div className="col-xl-3 col-lg-4 ">
            <div className="row">
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
                <PartDetails
                  part={part}
                  setPart={setPart}
                  categories={categories}
                />
              </div>

              <SourcePart part={part?.source_part}/>
              <TargetParts parts={part?.target_parts}/>

              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
                <PartCodeDetails part={part}/>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 ">
            <div className="row">
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <PartCostsDetails part={part}/>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <PartMedia part={part}/>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <PartPriceChart part={part}/>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <PartBoms part={part}/>
              </div>
            </div>

            <PartWorks partId={part?.id}/>
            <PartOffers partId={part?.id}/>
          </div>
        </div>

      </div>
    </>
  ) : <></>;
}

export default Part;
